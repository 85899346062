<div>
  <div class="background-image-container">
    <div class="container-fluid mb-5 ">
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="card bg-white w-100 mt-2 register-card"> <!-- Adjust height here -->
            <div class="card-body text-center d-flex align-items-center justify-content-center register-title-background">
              <h3 class="mt-3 mb-3 text-white display-1">Register for Orcaa</h3>
              <!-- Additional content (form fields, buttons, etc.) can go here -->
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Main Content Section -->
    <div class="container register-container">
      <!-- Scrollable Row for Options -->
      <div class="scroll-container">
        <!-- Option 1 -->
        <div class="card text-center h-auto mb-2">
          <div class="card-body">
            <h5 class="card-title">Register for Orcaa Corporate</h5>
            <p class="card-text">Create an account as a Company and explore our platform.</p>
            <a href="https://platform.orcaa.ai/content_creator_registration" class="btn btn-dark">Register Now</a>
          </div>
        </div>

        <!-- Option 2 -->
        <div class="card text-center h-auto mb-2">
          <div class="card-body">
            <h5 class="card-title">Register for Orcaa Internship</h5>
            <p class="card-text">Join as an Intern.</p>
            <a href="https://platform.orcaa.ai/content_creator_registration" class="btn btn-dark">Register Now</a>
          </div>
        </div>

        <!-- Option 3 -->
        <div class="card text-center h-auto mb-2">
          <div class="card-body">
            <h5 class="card-title">Register for Orcaa Plus</h5>
            <p class="card-text">Register your School and find talent.</p>
            <a href="https://platform.orcaa.ai/content_creator_registration" class="btn btn-dark">Register Now</a>
          </div>
        </div>

        <!-- Option 4 -->
        <div class="card text-center h-auto mb-2">
          <div class="card-body">
            <h5 class="card-title">Register for Orcaa Assist</h5>
            <p class="card-text">Create a parent account to manage your children’s activities.</p>
            <a href="https://platform.orcaa.ai/content_creator_registration" class="btn btn-dark">Register Now</a>
          </div>
        </div>

        <!-- Option 5 -->
        <div class="card text-center h-auto mb-2">
          <div class="card-body">
            <h5 class="card-title">Register for Orcaa Recruit</h5>
            <p class="card-text">Create an account to hire employees.</p>
            <a href="https://platform.orcaa.ai/content_creator_registration" class="btn btn-dark">Register Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
