import { createRouter, createWebHistory } from 'vue-router'
import Scroll_page from '@/views/Scroll_page/Scroll_page.vue'
import Home from '../components/Home/Home.vue'
import About_page from '@/components/About_page/About_page.vue'
import Sectors_page from '@/components/Sectors_page/Sectors_page.vue'
import Statistics_page from '@/components/Statistics_page/Statistics_page.vue'
import Payment_page from '@/components/Payment_page/Payment_page.vue'
import Footer_page from '@/components/Footer_page/Footer_page.vue'
import Internship_page from '@/components/Internship_page/Internship_page.vue'
import Plus_page from '@/components/Plus_page/Plus_page.vue'
import Assist_page from '@/components/Assist_page/Assist_page.vue'
import Main_home from '@/components/Main_home/Main_home.vue'
import Corporate_new_page from '@/components/Corporate_new_page/Corporate_new_page.vue'
import Recruit_page from '@/components/Recruit_page/Recruit_page.vue'
import Testimonies_page from '@/components/Testimonies_page/Testimonies_page.vue'
import Features_page from '@/components/Features_page/Features_page.vue'
import Contact_us_page from '@/components/Contact_us_page/Contact_us_page.vue'
import Event_landing_page from '@/components/Event_landing_page/Event_landing_page.vue'
import Register_options from '@/views/Register_options/Register_options.vue'




const routes = [
  {
    path: '/',
    name: 'scroll_page',
    component: Scroll_page
  },

  {
    path: '/home',
    name: 'home',
    component: Home
  },

  {
    path: '/about_page',
    name: 'about_page',
    component: About_page
  },

  {
    path: '/sectors_page',
    name: 'sectors_page',
    component: Sectors_page
  },

  {
    path: '/statistics_page',
    name: 'statistics_page',
    component: Statistics_page
  },

  {
    path: '/payment_page',
    name: 'payment_page',
    component: Payment_page
  },

  {
    path: '/footer_page',
    name: 'footer_page',
    component: Footer_page
  },

  {
    path: '/internship_page',
    name: 'internship_page',
    component: Internship_page
  },
  
  {
    path: '/plus_page',
    name: 'plus_page',
    component: Plus_page
  },

  {
    path: '/assist_page',
    name: 'assist_page',
    component: Assist_page
  },

  {
    path: '/main_home',
    name: 'main_home',
    component: Main_home
  },

  {
    path: '/corporate_new_page',
    name: 'corporate_new_page',
    component: Corporate_new_page
  },

  {
    path: '/recruit_page',
    name: 'recruit_page',
    component: Recruit_page
  },

  {
    path : '/testimonies_page',
    name: 'testimonies_page',
    component: Testimonies_page
  },

  {
    path : '/features_page',
    name: 'features_page',
    component: Features_page
  },

  {
    path : '/contact_us_page',
    name: 'contact_us_page',
    component: Contact_us_page
  },

  {
    path: '/event_landing_page',
    name: 'event_landing_page',
    component: Event_landing_page
  },

  {
    path: '/register_options',
    name: 'register_options',
    component: Register_options
  }

 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
