<div class="container-fluid">
    <div class=" ">
       <div class="row">
        <div class="col-12 text-center mt-3 mb-5 ">
          <div class="custom-logo-container style-logo logo-position ms-5">
            <img class="features-logo" src="@/assets/images/Orcaa.ai_Recruit.svg" alt="Orcaa logo">
            <h4 class="custom-logo-text mt-3">Orcaa<b> Recruit</b></h4>
          </div>
        </div>
      </div>
        <div class="card product-background-card ms-md-5 mt-5  me-5">
            <div class="row ">
                <div class="col-12 col-md-6">
                    <h4 class="question-text text-start ms-md-5 mt-2">What is Orcaa <b>Recruit</b>?</h4>
                    <p class="question-text-info text-start ms-md-5">OrcaaRecruit automates the recruitment process for HR and talent managers, addressing the challenges of time-consuming manual processes, inefficiencies in candidate screening, and hiring mismatches.</p>

                    <div class="line-with-text ms-md-5 mt-5">
                        <div class="vertical-line-metrics mb-1"></div>
                        <ul class="metrics-description ps-0">
                     <li class="ms-2"><b>Key Metrics</b></li>
                    <li class="text-muted"><box-icon name='group' color="#3e1590" class='metric-icon'></box-icon> 200+ Candidates applicatins processed</li>
                    <li class="text-muted"><box-icon name='door-open' color="#3e1590" class='metric-icon'></box-icon> +7 Partner Companies</li>
                    <li class="text-muted"><box-icon name='cog' color="#3e1590" class='metric-icon'></box-icon> +5 White Labelled Platforms</li>
                 </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="product-card recruit-content recruit-content-2  mt-4 mt-md-0">
                        <p class="product-title text-center">Benefits of Orcaa Recruit</p>
                        <br>
                    <h5 class="text-start fw-bold mini-product-topics">Improved Candidate Matches:</h5>
                    <p class="text-start mini-product-desc">Automates key recruitment tasks, reducing time-to-hire and freeing up HR resources for strategic activities.</p>
                    <h5 class="text-start fw-bold mini-product-topics">Faster Hiring Process:</h5>
                    <p class="text-start mini-product-desc">AI-driven insights help identify the best candidates for each role, leading to higher-quality hires.</p>
                    <h5 class="text-start fw-bold mini-product-topics">Cost Efficiency:</h5>
                    <p class="text-start mini-product-desc">Reduces the costs associated with traditional recruitment methods and manual screening processes.</p>
                
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>








