export default {
    data() {
      return {
        stat1: 0,
        stat2: 0,
        stat3: 0,
        target1: 48,
        target2: 60,
        target3: 55,
        incrementStep: 1, // Amount to increment per step
        updateInterval: 150, // Interval between increments in milliseconds
        pauseDuration: 9000, // Duration of the pause in milliseconds (50 seconds)
      };
    },
    mounted() {
      this.startIncrement();
    },
    methods: {
      startIncrement() {
        const update = () => {
          let allReached = true;
  
          // Update each stat
          if (this.stat1 < this.target1) {
            this.stat1 += this.incrementStep;
            if (this.stat1 > this.target1) this.stat1 = this.target1;
            allReached = false;
          }
          if (this.stat2 < this.target2) {
            this.stat2 += this.incrementStep;
            if (this.stat2 > this.target2) this.stat2 = this.target2;
            allReached = false;
          }
          if (this.stat3 < this.target3) {
            this.stat3 += this.incrementStep;
            if (this.stat3 > this.target3) this.stat3 = this.target3;
            allReached = false;
          }
  
          if (allReached) {
            // Pause for a while when all targets are reached
            setTimeout(() => {
              // Reset all stats to 0 and restart
              this.stat1 = 0;
              this.stat2 = 0;
              this.stat3 = 0;
              
              setTimeout(update, this.updateInterval);
            }, this.pauseDuration);
          } else {
            setTimeout(update, this.updateInterval);
          }
        };
  
        update(); // Start the incrementing process
      }
    },
  };