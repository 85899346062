<div class="about_page_background  ">
  <br><br>
  <div class="container orcaa-stat">
    <h1 class="header">Driving Excellence in Talent Development  <span>with Data-Driven
        Precision </span> </h1>
    <div class="row">
        <div class="col-md-3 col-sm-6">
            <div class="progress-item">
                 <div class="circle-progress cyan">
                    <div class="progress-icon">71% <i class='bx bxs-up-arrow text-xxs text-success'></i></div>
                </div>
                <div class="progress-title">Orcaa.ai Assist</div>
                <div class="progress-description">Boost in learner pass rates &
                    skills retention.</div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="progress-item">
                 <div class="circle-progress blue">
                    <div class="progress-icon">60% <i class='bx bxs-up-arrow text-xxs text-success'></i></div>
                </div>
                <div class="progress-title">Orcaa.ai Internships</div>
                <div class="progress-description">Quicker onboarding and
                    training lead to faster
                    productivity and improved
                    efficiency.</div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="progress-item">
                 <div class="circle-progress purple">
                    <div class="progress-icon">50% <i class='bx bxs-up-arrow text-xxs text-success'></i></div>
                </div>
                <div class="progress-title">Orcaa.ai Plus</div>
                <div class="progress-description">Improvement in talent
                    matching with roles that
                    enhance their strengths.</div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="progress-item"> 
                <div class="circle-progress pink">
                    <div class="progress-icon">50% <i class='bx bxs-up-arrow text-xxs text-success'></i></div>
                </div>
                <div class="progress-title">Orcaa.ai Recruit </div>
                <div class="progress-description"> 
                  Quicker onboarding and
                    training lead to faster
                    productivity and improved
                    efficiency

                </div>
            </div>
        </div>
    </div>
    <br><br>
</div>
    <div class="row d-none">
      <div class="col-12">
        <h2 class="stats-topic text-black display-6">
          Driving Excellence in Talent Development with Data-Driven <br>
          Precision
        </h2>
        <br>
        <h4 class="stats-topic-two text-muted display-6 fw-small">
          Harnessing powerful insights to optimize performance, improve learning outcomes,<br>
           and create future-ready teams.
        </h4>
        <br>
        <br>
      </div>
      
      <div class="row statistics-row">
        <div class="col-4">
          <h1 :class="['stats-topic-three', { 'glow': stat1 === target1 }]" class="fw-bold">{{ stat1 }}%</h1>
          <h4 class="stats-topic-two text-muted display-6 fw-small last-topic">
            Boost in learner pass rates & <br>
             skills retention.
          </h4>
        </div>
        <div class="col-4">
          <h1 :class="['stats-topic-three', { 'glow': stat2 === target2 }]" class="fw-bold">{{ stat2 }}%</h1>
          <h4 class="stats-topic-two text-muted display-6 fw-small last-topic">
            Quicker onboarding and <br>
             training lead to faster <br>
              productivity and improved <br>
              efficiency.
          </h4>
        </div>
        <div class="col-4">
          <h1 :class="['stats-topic-three', { 'glow': stat3 === target3 }]" class="fw-bold">{{ stat3 }}%</h1>
          <h4 class="stats-topic-two text-muted display-6 fw-small last-topic">
            Improvement in talent <br>
             matching with roles that <br>
              enhance their strengths.
          </h4>
        </div>
      </div>
      
    </div>
  </div>