<div class="container-fluid">
    <div class=" ">
       <div class="row">
        <div class="col-12 text-center mt-3 mb-5 ">
          <div class="custom-logo-container style-logo logo-position ms-5">
            <img class="features-logo" src="@/assets/images/Orcaa.ai_Plus.svg" alt="Orcaa logo">
            <h4 class="custom-logo-text mt-3">Orcaa<b> Plus</b></h4>
          </div>
        </div>
      </div>
        <div class="card product-background-card ms-md-5 mt-5 p-3 me-5">
            <div class="row ">
                <div class="col-12 col-md-6">
                    <h4 class="question-text text-start ms-md-5 mt-2">What is Orcaa <b>Plus</b>?</h4>
                    <p class="question-text-info text-start ms-md-5">OrcaaPlus is an AI-driven platform for learner evaluation and tracking. It supports students, admins, and educators in private schools, universities, and home schooling by augmenting existing educational systems and providing continuous feedback.</p>

                    <div class="line-with-text ms-md-5 mt-5">
                        <div class="vertical-line-metrics mb-1"></div>
                        <ul class="metrics-description ps-0">
                             <li class="ms-2"><b>Key Metrics</b></li>
                    <li class="text-muted"><box-icon name='group' color="#3e1590" class='metric-icon'></box-icon> 3000+ Females trained through code like a Girl Programme</li>
                    <li class="text-muted"><box-icon name='door-open' color="#3e1590" class='metric-icon'></box-icon> 5+ Countries used in</li>
                    </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="product-card plus-content mt-4 mt-md-0">
                        <p class="product-title text-center">Benefits of Orcaa Plus</p>
                        <br>
                    <h5 class="text-start fw-bold mini-product-topics">Enhanced Learning Outcomes:</h5>
                    <p class="text-start mini-product-desc">By providing continuous feedback and real-time insights.</p>
                    <h5 class="text-start fw-bold mini-product-topics">Support for Diverse Educational Settings:</h5>
                    <p class="text-start mini-product-desc">OrcaaPlus is adaptable and provides consistent support across different educational contexts.</p>
                    <h5 class="text-start fw-bold mini-product-topics">AI-Driven Efficiency:</h5>
                    <p class="text-start mini-product-desc">Leveraging AI technology, OrcaaPlus automates assessments and reporting, saving time for educators and providing accurate and objective evaluations of student progress.</p>
                 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

















