

<div class="container-fluid">
    <div class="">
       <div class="row">
        <div class="col-12 text-center mt-3 mb-5 ">
          <div class="custom-logo-container style-logo logo-position ms-5">
            <img class="features-logo" src="@/assets/images/Orcaa.ai_Assist.svg" alt="Orcaa logo">
            <h4 class="custom-logo-text mt-3">Orcaa<b> Assist</b></h4>
          </div>
        </div>
      </div>
        <div class="card product-background-card ms-md-5 mt-5 p-3 me-5">
            <div class="row">
             <div class="col-12 col-md-6">
                    <div class="product-card product-display-2 mt-md-0">
                        <p class="product-title text-center">Benefits of Orcaa Assist</p>
                       
                          <br>
                        <h5 class="text-start fw-bold mini-product-topics">Convenience for Parents:</h5>
                  <p class="text-start mini-product-desc">Enables parents to stay connected with their children’s education without disrupting their schedules.</p>
                  <h5 class="text-start fw-bold mini-product-topics">Enhanced Learning Support:</h5>
                  <p class="text-start mini-product-desc">Provides tutors and mentors with better tools to monitor and guide student learning.</p>
                  <h5 class="text-start fw-bold mini-product-topics">Scalability:</h5>
                  <p class="text-start mini-product-desc">Easily integrates into existing educational systems, offering flexibility and adaptability amongst various stakholders.</p>
              
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <h4 class="question-text text-start ms-md-5 mt-2">What is Orcaa <b>Assist</b>?</h4>
                    <p class="question-text-info text-start ms-md-5">OrcaaAssist addresses the problem of limited visibility for parents, tutors, and mentors into students’ academic progress, which can hinder timely support. The platform provides real-time updates and integrates with school systems to deliver timely assessments, interactive learning tools, and personalized feedback.</p>

                    <div class="line-with-text ms-md-5 mt-5">
                        <div class="vertical-line-metrics"></div>
                        <ul class="metrics-description ps-0">
                            <li class="ms-2"><b>Key Metrics</b></li>
                <li class="text-muted"><box-icon name='group' color="#3e1590" class='metric-icon'></box-icon> +150 Upskilling Candidates</li>
                <li class="text-muted"><box-icon name='door-open' color="#3e1590" class='metric-icon'></box-icon> +7 Partner Companies</li>
                <li class="text-muted"><box-icon name='cog' color="#3e1590" class='metric-icon'></box-icon> +5 White Labelled Platforms</li>
              </ul>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</div>




