export default {
  data() {
    return {
      selectedTab: 'tab1', // Initial selected tab
      aboutTopic: 'Transforming Educational Institutions with Real-Time Insights',
    };
  },
  methods: {
    handleTabChange(tabId, topic) {
      this.selectedTab = tabId;
      this.aboutTopic = topic; // Change heading when tab changes
    },
    scrollToPricelist() {
      const pricelist_section = document.getElementById("pricelist");
      if (pricelist_section) {
        pricelist_section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  
};