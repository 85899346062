<div class="background-section">
  <div class="container-fluid py-5">
    <div class="row align-items-center">
      <!-- Text Section -->
      <div class="col-12 col-md-6 text-center text-md-start mb-4 mb-md-0">
        <h3 class="text-start text-md-start text-center ms-md-5 ms-2 event-topic">
          Sponsored by Orcaa.ai
        </h3>
        <h1 class="text-start text-md-start text-center mt-5 ms-md-5 ms-2  fw-bold text-white">
          Learning & Development <br> Webinar
        </h1>
        <p class="text-start text-md-start text-center ms-md-5 ms-2 mt-2 event-topic-three">
          For <b>businesses and educational institutions</b> frustrated with traditional learning systems lacking integration and real-time insights
        </p>
        <p class="text-start text-md-start text-center ms-md-5 ms-2 mt-2 event-topic-three">
          <b class="text-info">Orcaa.ai</b> offers an <b>AI-driven platform with seamless learning, insights, and <br> automated reporting</b>
        </p>

        <!-- Countdown Timer on Small Cards -->
        <div class="countdown-cards d-flex justify-content-center mt-4">
          <div class="countdown-card">
            <p class="count">{{ days }}</p>
            <span>Days</span>
          </div>
          <div class="countdown-card">
            <p class="count">{{ hours }}</p>
            <span>Hours</span>
          </div>
          <div class="countdown-card">
            <p class="count">{{ minutes }}</p>
            <span>Minutes</span>
          </div>
          <div class="countdown-card">
            <p class="count">{{ seconds }}</p>
            <span>Seconds</span>
          </div>
        </div>

        <!-- Input Group with Buttons and Character Count -->
        <div class="container mt-5">
          <div class="input-group">
            <input 
              v-model="email" 
              type="text" 
              class="form-control" 
              placeholder="Enter email address" 
              aria-label="Text input" 
              @input="updateCount"
            />
            <button class="btn btn-primary" type="button" @click="addEmail">Register now</button>
          </div>
          
          <!-- Error Message for Invalid Email -->
          <div v-if="emailError" class="mt-2 text-danger">
            {{ emailError }}
          </div>

          <div class="text-end mt-2">
            <button class="btn btn-secondary login-button" type="button">Learn more</button>
          </div>
        </div>
      </div>

      <!-- Image Section -->
      <div class="col-12 col-md-6 text-center">
        <img src="@/assets/images/Event_Graphic.png" alt="Event Image" class="img-fluid rounded">
      </div>
    </div>
  </div>
</div>
