import mockupCorporate from '@/assets/images/orcaa-corp-mockup.png';
import mockupInternships from '@/assets/images/orcaa-intern-mockup.png';
import mockupPlus from '@/assets/images/orcaa-plus-mockup.png';
import mockupAssist from '@/assets/images/orcaa-assist-mockup.png';
import mockupRecruit from '@/assets/images/orcaa-recruit-mockup.png';

export default {
  data() {
    return {
      showNavbar: true, // Controls visibility of the navbar
      isMobileMenuOpen: false, // Controls visibility of the mobile menu
      scrollAmount: 300,
      defaultColor: '#9747FF',
      defaultImage: '', // Set in mounted hook
      activeImage: '', // To track which card is active
      cards: [
        { color: "#0000FF", image: mockupAssist, title: "Assist", suite: "Orcaa.ai Suite" },
        { color: "#004aad", image: mockupInternships, title: "Internships", suite: "Orcaa.ai Suite" },
        { color: "#0097b2", image: mockupPlus, title: "Plus", suite: "Orcaa.ai Suite" },
        { color: "#38b6ff", image: mockupRecruit, title: "Recruit", suite: "Orcaa.ai Suite" },
        { color: "#453cf9", image: mockupCorporate, title: "X", suite: "Orcaa.ai Suite" },

      ]
    };
  },
  mounted() {
    // Initialize the default image in the mounted lifecycle hook
    this.defaultImage = this.$refs.heroSuite.src;

    // Initial check for scroll buttons
    this.updateScrollButtons();
  },
  methods: {
    hideNavbar() {
      this.showNavbar = false;
      this.$nextTick(() => {
        document.querySelector('.navbar-main').classList.add('adjusted');
      });
    },
    toggleMenu() {
      // Toggles the mobile menu
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },

    // Scroll functionality for cards
    scrollCards(direction) {
      const wrapper = this.$refs.cardsWrapper;
      const scrollAmount = direction === 'next' ? this.scrollAmount : -this.scrollAmount;
      wrapper.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    },

    // Card hover effects
    hoverCard(card) {
      this.$refs.purpleCircle.style.backgroundColor = card.color;
      this.$refs.heroSuite.src = card.image;
      this.$refs.heroSuite.style.transform = 'scale(1.1)';
    },
    resetHover() {
      this.$refs.purpleCircle.style.backgroundColor = this.defaultColor;
      this.$refs.heroSuite.src = this.defaultImage;
      this.$refs.heroSuite.style.transform = 'scale(1)';
    },

    // Card click effects
    clickCard(card) {
      this.defaultColor = card.color;
      this.defaultImage = card.image;
      this.$refs.purpleCircle.style.backgroundColor = card.color;
      this.$refs.heroSuite.src = card.image;
      this.activeImage = card.image;
    },

    // Show/hide scroll buttons based on scroll position
    updateScrollButtons() {
      const wrapper = this.$refs.cardsWrapper;
      const maxScroll = wrapper.scrollWidth - wrapper.clientWidth;
      this.$refs.prevBtn.style.display = wrapper.scrollLeft <= 0 ? 'none' : 'block';
      this.$refs.nextBtn.style.display = wrapper.scrollLeft >= maxScroll ? 'none' : 'block';
    }
    ,
    scrollToPricelist() {
      const pricelist_section = document.getElementById("pricelist");
      if (pricelist_section) {
        pricelist_section.scrollIntoView({ behavior: "smooth" });
      }
    }, 
    scrollToFeatures() {
      const features_section = document.getElementById("features_section");
      if (features_section) {
        features_section.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToMoreFeatures() {
      const more_features_section = document.getElementById("more_features_section");
      if (more_features_section) {
        more_features_section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  watch: {
    showNavbar(newValue) {
      if (newValue) {
        document.querySelector('.navbar-main').classList.remove('adjusted');
      }
    },
    // Watch the scroll position to update button visibility
    '$refs.cardsWrapper.scrollLeft'() {
      this.updateScrollButtons();
    }
  }
};
