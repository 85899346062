export default {
  name: "CountdownTimer",
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      countdownDate: new Date("2024-10-25T00:00:00").getTime(),
      interval: null,
    };
  },
  methods: {
    updateCountdown() {
      const now = new Date().getTime();
      const distance = this.countdownDate - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(this.interval);
        this.days = this.hours = this.minutes = this.seconds = 0;
      }
    },
  },

  mounted() {
    this.updateCountdown();
    this.interval = setInterval(this.updateCountdown, 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
};
