export default {
  data() {
    return {
      selectedTab: 'tab1',
    };
  },
  methods: {
    handleTabChange(tab) {
      this.selectedTab = tab;
    },
  },
  mounted() {
    document.querySelectorAll('.feature-item').forEach(item => {
      item.addEventListener('click', () => {
        item.classList.toggle('expanded');
      });
    });
  }
};
