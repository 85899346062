<div class="footer-background">
    <div class="space">

    </div>
    <footer class="footer-content text-white pt-5 pb-4">

        <div class="container text-center text-md-left">
    
            <div class="row text-center text-md-left">
    
                <div class="col-md-3 col-lg-3 col-xl-3 mx-auto">
                    <h5 class="text-start font-weight-bold text-white">Orcaa.ai Suites</h5>
                    <br>
                    <p class="text-start">Orcaa.ai Assist</p>
                    <p class="text-start">Orcaa.ai Plus</p>
                    <p class="text-start">Orcaa.ai X</p>
                    <p class="text-start">Orcaa.ai Internship</p>
                    <p class="text-start">Orcaa.ai Recruit</p>
                    
                </div>
    
                
    
                <div class="col-md-3 col-lg-2 col-xl-2 mx-auto">
                    <h5 class="text-start font-weight-bold text-white">Get in touch</h5>
                    <br>
                    <p class="text-start">Request A Demo</p>
                    <p class="text-start">Partner With us</p>
                    <p class="text-start">Email Us</p>
                    
                </div>
    
                <div class="col-md-4 col-lg-3 col-xl-3 mx-auto">
                    <h5 class="text-start font-weight-bold text-white">Get in touch</h5>
                    <br>
                    <p class="text-start">About us</p>
                    <p class="text-start">Careers</p>
                    <p class="text-start">Press</p>
                    <p class="text-start">Partnership</p>
                    
                </div>
                
            </div>
    
            <hr class="mb-2">
    
            <div class="row align-items-center">
    
                <div class="col-md-7 col-lg-8 text-end">
                    <p>	Copyright ©2024 All rights reserved by EPF Innovation Campus</p>
                        
                    
                </div>
    
                <div class="col-md-5 col-lg-4 ">
                    <div class="text-center text-md-right">
    
                        <ul class="list-unstyled list-inline text-end">
                            <li class="list-inline-item">
                                <router-link to="" class="btn-floating btn-sm text-white"><box-icon name='instagram' type='logo' color='#ffffff' ></box-icon></router-link>
                            </li>
                            <li class="list-inline-item">
                                <router-link to="" class="btn-floating btn-sm text-white"><box-icon name='linkedin-square' type='logo' color='#ffffff' ></box-icon></router-link>
                            </li>
                            <li class="list-inline-item">
                                <router-link to="" class="btn-floating btn-sm text-white"><box-icon name='youtube' type='logo' color='#ffffff' ></box-icon></router-link>
                            </li>
                            
                        </ul>
                        
                    </div>
                    
                </div>
                
            </div>
    
        </div>
        
    </footer>
    
</div>
