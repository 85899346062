

<div class="container-fluid">
    <div class=" ">
       <div class="row">
        <div class="col-12 text-center mt-3 mb-5 ">
          <div class="custom-logo-container style-logo logo-position ms-5">
            <img class="features-logo" src="@/assets/images/Orcaa.ai_Internship.svg" alt="Orcaa logo">
            <h4 class="custom-logo-text mt-3">Orcaa<b> Internship</b></h4>
          </div>
        </div>
      </div>
        <div class="card product-background-card ms-md-5 mt-5 p-3 me-5">
            <div class="row">
             <div class="col-12 col-md-6">
                    <div class="product-card product-display-2 mt-md-0">
                        <p class="product-title text-center">Benefits of Orcaa Internship</p>
                       
                          <br>
                       <h5 class="text-start fw-bold mini-product-topics">Enhanced Internship Programs:</h5>
                       <p class="text-start mini-product-desc">Ensures meaningful skill development during internships.</p>
                      <h5 class="text-start fw-bold mini-product-topics">Improved Role Matching:</h5>
                      <p class="text-start mini-product-desc">Align interns with roles that best fit their capabilities</p>
                      <h5 class="text-start fw-bold mini-product-topics">Employability Assessments:</h5>
                      <p class="text-start mini-product-desc">Supports the creation of a skilled and capable future workforce.</p>
              
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <h4 class="question-text text-start ms-md-5 mt-2">What is Orcaa <b>Internship</b>?</h4>
                    <p class="question-text-info text-start ms-md-5">Solves the problem of the disconnect between academic learning and the practical skills needed in the workplace. It ensures that interns are matched to roles aligned with their strengths, monitors their progress, and provides real-time feedback to prepare them for full-time employment.</p>

                    <div class="line-with-text ms-md-5 mt-5">
                        <div class="vertical-line-metrics"></div>
                        <ul class="metrics-description ps-0">
                            <li class="ms-2"><b>Key Metrics</b></li>
                            <li class="text-muted"><box-icon name='group' color="#3e1590" class='metric-icon'></box-icon> +150 Upskilling Candidates</li>
                            <li class="text-muted"><box-icon name='door-open' color="#3e1590" class='metric-icon'></box-icon> +7 Partner Companies</li>
                            <li class="text-muted"><box-icon name='cog' color="#3e1590" class='metric-icon'></box-icon> 10+ Corporate Bootcamps hosted</li>
                        </ul>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</div>






