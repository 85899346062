export default {
  methods: {
    scrollToProducts() {
      const productsSection = document.getElementById('products-section');
      productsSection.scrollIntoView({ behavior: 'smooth' });
    },
    scrollToProductsTwo() {
      const productsSectionTwo = document.getElementById('products-section-two');
      productsSectionTwo.scrollIntoView({ behavior: 'smooth' });
    },
    scrollToProductsThree() {
      const productsSectionTwo = document.getElementById('products-section-three');
      productsSectionTwo.scrollIntoView({ behavior: 'smooth' });
    },
    scrollToProductsFour() {
      const productsSectionTwo = document.getElementById('products-section-four');
      productsSectionTwo.scrollIntoView({ behavior: 'smooth' });
    },
    scroll_to_sector_sec() {
      const sectorSection = document.getElementById("sectors-section");
      if (sectorSection) {
        sectorSection.scrollIntoView({ behavior: "smooth" });
      }
    },
  }
};
