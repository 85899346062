<div class="container-fluid">
    <div class="">  
        <h4 class="text-white text-start ms-5 mt-5 testimonials_topic">___ Our testimonials</h4>
        <br>
        <br>
        <div class="text-center">
            <h1 class="text-white fw-bold text-start d-inline-block testimonials_slogan">
                Delivering Excellence in Talent Management,<br>
                Recruitment, and Learning Development – That’s <br>
                OrcaaSuite.
            </h1>
        </div>
        <br>
        <div class="row">
            <!-- First Testimonial -->
            <div class="col-12 col-md-4">
                <div class="text-center testimonials">
                    <p class="text-white text-start d-inline-block">
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <br>
                        "OrcaaInternships has completely transformed how we manage our learning programs. The AI-driven assessments and real-time reporting have made it easier to track progress and ensure success."
                        <br><br>
                        <b>Lyle Peterson</b><br>
                        Learning and Development Manager
                    </p>
                </div>
            </div>
            
            <!-- Second Testimonial -->
            <div class="col-12 col-md-4">
                <div class="text-center testimonials">
                    <p class="text-white text-start d-inline-block">
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <br>
                        "Thanks to OrcaaRecruit, we've significantly cut down on hiring time. The automated shortlisting and interview scheduling have made our recruitment process more efficient and accurate."
                        <br><br>
                        <b>Violet Llale</b><br>
                        Internship Program Coordinator
                    </p>
                </div>
            </div>
            
            <!-- Third Testimonial -->
            <div class="col-12 col-md-4">
                <div class="text-center testimonials">
                    <p class="text-white text-start d-inline-block">
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star' type='solid' color='#f8de00'></box-icon>
                        <box-icon name='star-half' type='solid' color='#f8de00'></box-icon>
                        <br>
                        "OrcaaPlus has enhanced our student monitoring system, allowing us to provide more targeted support and improve overall performance. Parents love the real-time updates!"
                        <br><br>
                        <b>Mahlodi Raphala</b><br>
                        School Principal
                    </p>
                </div>
            </div>
        </div>
        <br>
    </div>
</div>
