<div>
 
    <!-- Top Navbar -->
    <nav class="navbar navbar-one fixed-top navbar-light bg-light d-none" v-if="showNavbar">
      <div class="container-fluid custom-nav">
        <div class="d-flex align-items-center w-100 justify-content-end">
          <a class="navbar-brand nav-one fs-4 fs-md-5 fs-lg-6 me-0 text-truncate nav-one-topic" href="#">
            AI-Powered Skills Evaluation for a Future-Ready Workforce
          </a>
          <div class="d-none d-md-block vertical-line me-3 d-none d-sm-block"></div>
          <a class="nav-one d-flex align-items-center nav-one-topic-two fs-4 fs-md-5 fs-lg-6 ms-5" href="/register_options">
            Register now
            <box-icon name="right-arrow-alt" color="#ffffff"></box-icon>
          </a>
          <button class="btn btn-primary cancel-btn fs-4 fs-md-5 fs-lg-6 ms-3" @click="hideNavbar">X</button>
        </div>
      </div>
    </nav>

    <nav class="navbar navbar-expand-md navbar-main navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#" style="display: flex; align-items: center;">
      <img src="@/assets/images/Orcaa.ai.svg" alt="Logo" style="height: 30px; width: auto;" />
      <h6 class="orcaa-original-logo">Orcaa <b>ai</b></h6>
    </a>
    <button
      class="navbar-toggler bg-transpart border-0 text-xs"
      type="button"
      @click="toggleMenu"
      aria-controls="navbarNav"
      :aria-expanded="isMobileMenuOpen.toString()"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" :class="{ show: isMobileMenuOpen }" id="navbarNav">
      <ul class="navbar-nav ms-auto mt-2 navigator-item">
        <!-- First Dropdown -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Home
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Use cases</a></li>
            <li><a class="dropdown-item" href="#">Benefits</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Testimonials</a></li>
          </ul>
        </li>

        <!-- Second Dropdown -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="featuresDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Features
          </a>
          <ul class="dropdown-menu" aria-labelledby="featuresDropdown">
            <li><a class="dropdown-item"  @click="scrollToFeatures">Recruitment</a></li>
            <li><a class="dropdown-item"  @click="scrollToFeatures">Skills Auditing</a></li>
            <li><a class="dropdown-item"  @click="scrollToFeatures">Learning & Training</a></li>
            <li><a class="dropdown-item"  @click="scrollToFeatures">Assessments</a></li>
            <li><a class="dropdown-item"  @click="scrollToMoreFeatures">KPI & Interventions</a></li>
            <li><a class="dropdown-item"  @click="scrollToMoreFeatures">Maketplace</a></li> 
          </ul>
        </li>

        <!-- Third Dropdown -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="pricingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Pricing
          </a>
          <ul class="dropdown-menu" aria-labelledby="pricingDropdown">
            <li><a class="dropdown-item" @click="scrollToPricelist">Orcaa Corporate</a></li>
            <li><a class="dropdown-item" @click="scrollToPricelist">Orcaa Internship</a></li>
            <li><a class="dropdown-item" @click="scrollToPricelist">Orcaa Plus</a></li>
            <li><a class="dropdown-item" @click="scrollToPricelist">Orcaa Assist</a></li>
            <li><a class="dropdown-item" @click="scrollToPricelist">Orcaa Recruit</a></li>
          </ul>
        </li>

        <!-- Disabled Link (without dropdown) -->
        <li class="nav-item">
          <button class="btn login-button" type="button"  @click="scrollToPricelist">
            Get Started
          </button>
        </li>
        
      </ul>

    </div>
  </div>
</nav>

  </div>

  <div class="  main-background">
    <section class="hero-section    ">
      <div class="hero">
        <div class="row">

          <div class="col-md-6 col-sm-12 align-content-center">
          
            <div class="hero-content text-start w-100">
              <h1 class="text-lg fw-bold">Your Transparent Skills Evaluation Platform</h1>
              <p >
                 Choose Your Perfect <b>Orcaa.ai Suite</b> Today!
              </p>
              <button class="cta-button">Book a Demo</button>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="hero-image col-6-md col-sm-12">
              <div class="purple-circle" ref="purpleCircle"></div>
              <img src="@/assets/images/orcaa-orcaa-mockup.png" id="hero-shoe" ref="heroSuite" class="img-fluid">
            </div> 
          </div>

        </div>
      </div>
  
      <div class="cards-container">
        <button class="scroll-btn prev-btn" ref="prevBtn" @click="scrollCards('prev')">←</button>
        <div class="cards-wrapper p-5" ref="cardsWrapper" @scroll="updateScrollButtons">
          <div 
            class="card" 
            v-for="(card, index) in cards" 
            :key="index"
            @mouseover="hoverCard(card)" 
            @mouseleave="resetHover"
            @click="clickCard(card)"
            :class="{ 'active': activeImage === card.image }"
          >
            <img :src="card.image">
            <h5 class="text-start fw-normal">{{ card.title }}</h5>
            <p class="text-start">{{ card.suite }}</p>
          </div>
        </div>
        <button class="scroll-btn next-btn" ref="nextBtn" @click="scrollCards('next')">→</button>
      </div>

    </section>
  </div> 
  
  