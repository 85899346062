<div class=" "> 
  <br>
  <div class="container py-5 orcaa-ecosystem mb-5 ecosystem-md">
    <h1 class="text-center fw-bold mb-4 text-dark">How Orcaa.ai Suite Is Used In Ecosystems</h1>
    <br>
    <div class="row tab-container text-start partners-section-md">
        <!-- Decorative Elements -->
        <div class="circle-decoration circle-1"></div>
        <div class="circle-decoration circle-2"></div>
        <div class="circle-decoration circle-3"></div> 
        <div class="ring ring-1"></div>
        <div class="ring ring-2"></div>
        
      <!-- Side Tabs -->
      <div class="col-md-4 col-sm-12 side-tabs">
        <div class="tab-item" @mouseover="showContent(1)">
          <h4>Empire Partner Foundation</h4> 
        </div>
        <div class="tab-item" @mouseover="showContent(2)">
          <h4>Empire Partner Foundation</h4>
          <p class="mb-0">Innovation Campus</p>
        </div>
        <div class="tab-item" @mouseover="showContent(3)">
          <h4>Empire Partner Foundation</h4>
          <p class="mb-0">Tech Hub</p>
        </div>
      </div>

      <!-- Content Area -->
      <div class="col-md-8  col-sm-12 content-area">
        <div :class="['content-section', { active: currentTab === 1 }]">
          <div class="content-text">
            <h2>Orcaa.ai <span class="text-purple fw-bold">Recruit</span></h2>
            <p class="lead">Connect and collaborate with partners seamlessly through our integrated platform.</p>
            <p class="card-text">- Hosting Hackathon</p>
            <p class="card-text">- Learning & Training</p> 
            <p class="card-text">- Market Place</p>                    
          </div>
          <div class="image-container">
            <div class="purple-circle"></div>
            <img src="@/assets/images/orcaa-logo-purple.png" alt="orcaa-ecosystem Integration" class="feature-image">
          </div>
        </div>

        <div :class="['content-section', { active: currentTab === 2 }]">
          <div class="content-text">
            <h2>Orcaa.ai <span class="text-blue fw-bold">X</span></h2>
            <p class="lead">Seamlessly integrates with corporate systems.</p>
            <p class="card-text">- Skills Auditing</p>
            <p class="card-text">- Learning & Training</p>
            <p class="card-text">- KPI & Interventions</p>
            <p class="card-text">- Assessments</p>
            <p class="card-text">- Market Place</p> 
          </div>
          <div class="image-container">
            <div class="purple-circle"></div>
            <img  src="@/assets/images/orcaa-logo-blue.png" alt="Automated Workflows" class="feature-image">
          </div>
        </div>

        <div :class="['content-section', { active: currentTab === 3 }]">
          <div class="content-text">
            <h2 class="fw-bold">Orcaa.ai <span class="text-purple fw-bold">X</span></h2>
            <p class="lead">Driving change with innovation.</p>
            <p class="card-text">- Manage skills in startups</p>
            <p class="card-text">- KPI & Interventions</p> 
            <p class="card-text">- Market Place</p> 
          </div>
          <div class="image-container">
            <div class="purple-circle"></div>
            <img src="@/assets/images/orcaa-logo-purple.png" alt="Growth Analytics" class="feature-image">
          </div>
        </div>
      </div>
    </div>

    <div class="container py-4  partners-section-sm">
      <!-- Product 1 -->
      <div class="partner-card">
        <img src="@/assets/images/epf-logo.png" alt="orcaa-ecosystem Integration" class="img-fluid h-25 w-25">

        <p class="fw-bold">Empire Partner Foundation</p>
        <p class="fw-bold">Orcaa.ai <span class="text-purple fw-bold">Recruit</span></p> 

          <div class="row align-items-center">             
               <p class="card-text">Hosting Hackathon</p>
              <p class="card-text">Learning & Training</p> 
              <p class="card-text">Market Place</p>  
          </div>
      </div>
      <br>
      <!-- Product 2 -->
      <div class="partner-card">
        <img src="@/assets/images/ic-logo.png" alt="orcaa-ecosystem Integration" class="img-fluid h-25 w-25">

        <p class="fw-bold">EPF Innovation Capmus</p>
        <p  class="fw-bold">Orcaa.ai <span class="text-purple fw-bold"> X</span></p>  
          <div class="row align-items-center">
           
              
            <p class="card-text">Skills Auditing</p>
          <p class="card-text">Learning & Training</p>
          <p class="card-text">KPI & Interventions</p>
          <p class="card-text">Assessments</p>
          <p class="card-text">Market Place</p>  
          </div>
      </div>
      <br>
      <!-- Product 3 -->
      <div class="partner-card">
        <img src="@/assets/images/epf-logo.png" alt="orcaa-ecosystem Integration" class="img-fluid h-25 w-25">

        <p class="fw-bold">EPF Tech Hub</p>
        <p>Orcaa.ai <span class="text-purple fw-bold"> X</span></p>  

        <div class="row align-items-center"> 
          <p class="card-text">Manage skills in startups</p>
          <p class="card-text">KPI & Interventions</p> 
          <p class="card-text">Market Place</p> 
        </div>
      </div>
    </div>
  </div>  
</div>
