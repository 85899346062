import Home from '@/components/Home/Home.vue';
import About_page from '@/components/About_page/About_page.vue';
import Sectors_page from '@/components/Sectors_page/Sectors_page.vue';
import Statistics_page from '@/components/Statistics_page/Statistics_page.vue';
import Payment_page from '@/components/Payment_page/Payment_page.vue';
import Footer_page from '@/components/Footer_page/Footer_page.vue';
import Internship_page from '@/components/Internship_page/Internship_page.vue';
import Plus_page from '@/components/Plus_page/Plus_page.vue';
import Assist_page from '@/components/Assist_page/Assist_page.vue';
import Main_home from '@/components/Main_home/Main_home.vue'
import Corporate_new_page from '@/components/Corporate_new_page/Corporate_new_page.vue';
import Recruit_page from '@/components/Recruit_page/Recruit_page.vue';
import Testimonies_page from '@/components/Testimonies_page/Testimonies_page.vue';
import Features_page from '@/components/Features_page/Features_page.vue';
import Contact_us_page from '@/components/Contact_us_page/Contact_us_page.vue';
import Event_landing_page from '@/components/Event_landing_page/Event_landing_page.vue'


export default {
  components: {
    Home,
    About_page,
    Sectors_page,
    Statistics_page,
    Payment_page,
    Footer_page,
    Internship_page,
    Plus_page,
    Assist_page,
    Main_home,
    Corporate_new_page,
    Recruit_page,
    Testimonies_page,
    Features_page,
    Contact_us_page,
    Event_landing_page
    
  }
};