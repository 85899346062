export default {
  data() {
    return {
      currentTab: 1, // Start with the first tab active
    };
  },
  methods: {
    showContent(tabNumber) {
      this.currentTab = tabNumber;
    }, 
  }, 
};